import styled from '@emotion/styled';
import { LocationLink } from './Location-Link';
import { Title } from './StyledComponents';

export const GetInTouchStyled = styled.section`
    padding: 64px 0px;
    .underline:after {
        margin: 0 0 0;
    }
    .location:first-of-type {
        margin-bottom: 40px;
    }
    a {
        margin-bottom: 24px;
    }

    .location {
        p {
            color: ${({ theme }) => theme.colors.blue3};
            font-weight: 600;
            margin-bottom: 16px;
            font-size: 20px;
            line-height: 32px;
        }
    }
    .porter {
        margin-bottom: 24px;
    }
    iframe {
        border-radius: 15px;
        width: 100%;
        margin-bottom: 40px;
    }
    @media ${({ theme }) => theme.device.tablet} {
        .copy-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            h2 {
                width: 88vw;
            }
            > div:first-of-type {
                width: 320px;
            }
            > div:last-of-type {
                width: 300px;
                transform: translateY(20px);
            }
        }
    }
    @media ${({ theme }) => theme.device.laptop} {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        padding: 64px 0px 80px;

        iframe {
            width: 543px;
            height: 653px;
            margin-top: 32px;
        }
        .copy-container {
            display: block;
            width: 432px;
            h2 {
                width: 428px;
            }
            > div:first-of-type {
                width: auto;
            }
            > div:last-of-type {
                width: 430px;
                transform: translateY(20px);
            }
        }
    }
    @media ${({ theme }) => theme.device.laptopL} {
        iframe {
            position: relative;
            top: 32px;
            width: 649px;
            height: 653px;
            margin-top: 32px;
        }
    }
`;

const Hours = styled.div`
    margin: 0;
    display: flex;
    justify-content: space-between;
    ul {
        list-style: none;
        span {
            display: block;
            color: ${({ theme }) => theme.colors.gray5};
        }
        li {
            margin-bottom: 16px;
        }
    }
    ul:first-of-type {
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        text-align: left;
    }
    ul:last-of-type {
        font-size: 16px;
        line-height: 24px;
        text-align: right;
    }
`;

export const GetInTouch: React.FC = () => {
    return (
        <GetInTouchStyled className="large-container">
            <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3291.935544475878!2d-118.46117294970527!3d34.40298418041635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80c289a4da7c57a1%3A0x4c311ee1864ab811!2sKidz%20Dental%20Care!5e0!3m2!1sen!2sie!4v1679700527487!5m2!1sen!2sie"
                width="600"
                title="map of kidz dental care"
                height="450"
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
            <div className="copy-container">
                <div>
                    <Title side="left" className="header-2 underline">
                        Get In Touch
                    </Title>
                    <div className="location">
                        <p>Santa Clarita</p>
                        <LocationLink type="map" location="santa clarita" />
                        <LocationLink type="phone" location="santa clarita" />
                    </div>
                    <Hours style={{ marginBottom: '38px' }} className="hours-container">
                        <ul>
                            <li>Monday - Thursday</li>
                            <li>Friday - Saturday - Sunday</li>
                        </ul>
                        <ul>
                            <li>9:00 am - 4:30 pm</li>
                            <li style={{ opacity: '.7' }}>Closed</li>
                        </ul>
                    </Hours>
                    <div className="porter location">
                        <p>Porter Ranch</p>
                        <LocationLink type="map" location="porter ranch" />
                        <LocationLink type="phone" location="porter ranch" />
                    </div>
                    <Hours className="hours-container">
                        <ul>
                            <li>Monday - Friday</li>
                            <li>Saturday - Sunday</li>
                        </ul>
                        <ul>
                            <li>8:00 am - 5:00 pm</li>
                            <li style={{ opacity: '.7' }}>Closed</li>
                        </ul>
                    </Hours>
                </div>
            </div>
        </GetInTouchStyled>
    );
};
